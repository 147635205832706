import './Mobile.css';

function Mobile() {
  return (
    <div className="Mobile">
      <p>Please visit the site on a desktop browser</p>
    </div>
  );
}

export default Mobile;
